import ApiBlog from '@/api/blog.js'

export default {
	name: 'PostList',
	created () {
		ApiBlog.getAll(this)
	},
	methods: {
		trimText(text, numWords) {
			const words = text.split(" ");
			if (words.length > numWords) {
				const trimmedText = words.slice(0, numWords).join(" ") + "...";
				return this.cleanFromHtmlTags(trimmedText);
			} else {
				return this.cleanFromHtmlTags(text);
			}
		},
		cleanFromHtmlTags: text => text.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')
	},
	props: {
		content: {
			type: Array,
			required: true
		}
	}
}
